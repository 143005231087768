app-input-clear-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	// button {
	// 	@apply text-icon-dark;

	// 	&:focus {
	// 		@apply outline-none;
	// 		@apply border-secondary;
	// 		@apply border;
	// 		@apply rounded-btn;
	// 	}
	// }
}

