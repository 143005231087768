// tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import './styles/material/index';
@import './styles/custom/index';


@import "~intl-tel-input/build/css/intlTelInput.css";


html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.flex {
	display: flex;
}

.flex-v {
	display: flex;
	flex-direction: column;
}

.mat-mdc-row:hover {
	background: whitesmoke !important;
}

.entity-container {
	padding: 16px;
}

.entity-actions {
	text-align: right;

	.mdc-button {
		margin-bottom: 16px;
	}

	> * {
		margin-left: 16px;
	}
}

.table-container {
	position: relative;
}

.loading-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.15);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mat-mdc-no-data-row {
	height: 56px;

	td {
		padding: 20px;
	}
}

.actions {
	> * {
		margin-right: 16px;
	}
}

.mat-column-actions {
	width: 160px;

	> button {
		margin-right: 16px;
	}
}

#fieldRef {
	margin-bottom: 8px;
	display: flex;
	align-content: center;

	.square {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}

	.input-square {
		background-color: #5794ff;
	}
	.alg-square {
		background-color: #67d373;
		margin-left: 16px;
	}
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.center {
	justify-content: center;
	text-align: center !important;
	> div {
		justify-content: center;
	}
}

.mat-mdc-table .mdc-data-table__row {
	height: 40px;
}
