
ib-phone {
	display: block;
	width: 100%;

	.iti,
	.iti.iti--allow-dropdown {
		display: block;
		// @apply shadow-ff;
		// @apply border-none;
		// @apply rounded-input;


		.iti__flag-container {
			&:hover {
				.iti__selected-flag {
					// background-color: transparent;
				}
			}
		}

		.iti__selected-flag {
			// padding-left: 16px;
			// padding-right: 16px;

			&:focus-visible {
				// @apply outline-1;
				// @apply outline-secondary;
				// @apply border;
				// @apply rounded-btn;
			}

		}

		.iti__country-list {
		}

		input.ib-phone-iti {
			// width: 100%;
			// padding-left: 65px;
			// @apply h-input;
			// @apply border;
			// @apply rounded-input;
			// @apply border-transparent;
			// @apply text-inp-txt;
			// @apply text-inp-txt;
			// @apply rounded-input;
			// @apply placeholder-inp-placeholder;

			&:focus {
				// @apply text-inp-txt #{!important};
				// @apply outline-none #{!important};
				// @apply border-secondary #{!important};
			}
		}
	}
}

// states

ib-phone {
	// display: block;
	// width: 100%;

	&.ib-phone--focused {
		.iti,
		.iti.iti--allow-dropdown {

			// @apply shadow-none;
			// @apply border;
			// @apply border-secondary;
		}
	}

	&.ib-phone--invalid.ib-phone--touched {
		.iti,
		.iti.iti--allow-dropdown {
			// box-sizing: border-box;
			// @apply ib-input-error-left-border;

			input.ib-phone-iti {

			}
		}

		&.ib-phone--focused {
			.iti.iti--allow-dropdown {
				input.ib-phone-iti {
					// @apply shadow-ff-err #{!important};
					// @apply border-transparent #{!important};
				}
			}
		}
	}

	&.ib-phone--disabled {
		input {
			// user-select: none;
			// pointer-events: none;
			// cursor: default!important;
			// @apply bg-inp-bg-disabled #{!important};

			&:focus {
				// border: none #{!important};
			}
		}

		.iti__flag-container {
			// cursor: default!important;
		}
	}
}

